/**
 * Created by wangtengteng on 2015/11/26.
 */
// let meta = document.createElement('meta');
// meta.setAttribute('name', 'viewport');
// meta.setAttribute('id', 'viewport');
// meta.setAttribute('content', 'target-densitydpi=device-dpi,width=980, user-scalable=no');
// document.head.appendChild(meta);
// let fixViewport = function() {
//     let phoneWidth = parseInt(window.screen.width);
//     let phoneScale = phoneWidth / 980;
//     let ua = navigator.userAgent;
//     let dpi =  980 / phoneWidth * window.devicePixelRatio * 160;
//     if (/Android.*?(\d+\.\d+)/.test(ua)) {
//         let version = parseFloat(RegExp.$1);
//         if (version <= 2.3) {
//             document.getElementById('viewport').content = 'width=980, target-densitydpi=' + dpi;
//         } else {
//             document.getElementById('viewport').content = 'width=980, user-scalable=no, target-densitydpi=' + dpi;
//         }
//     }
// }
// fixViewport();


// 禁用双指放大
document.documentElement.addEventListener(
    'touchstart',
    function(event) {
        if (event.touches.length > 1) {
            event.preventDefault()
        }
    },
    {
        passive: false
    }
)

// 禁用双击放大
let lastTouchEnd = 0
document.documentElement.addEventListener(
    'touchend',
    function(event) {
        let now = Date.now()
        if (now - lastTouchEnd <= 300) {
            event.preventDefault()
        }
        lastTouchEnd = now
    },
    {
        passive: false
    }
)

